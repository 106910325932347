import { ListColumn, Filter } from '@Widgets/ResourceList/interface';

export const GIFTING_LIST_COLUMNS: ListColumn<CoreRemoteGifting>[] = [
  {
    key: 'id',
    type: 'text',
    source: 'id',
    label: 'promotions.pages.gifting.columns.id',
  },
  {
    key: 'name',
    type: 'text',
    source: 'name',
    label: 'promotions.pages.gifting.columns.name',
    sortable: false,
  },
  {
    key: 'status',
    type: 'boolean',
    source: 'isActive',
    label: 'promotions.pages.gifting.columns.status',
    sortable: false,
  },
  {
    // eslint-disable-next-line
    // @ts-ignore
    source: 'dateRange.0',
    type: 'date',
    label: 'promotions.pages.gifting.columns.startDate',
    sortable: false,
  },
  {
    // eslint-disable-next-line
    // @ts-ignore
    source: 'dateRange.1',
    type: 'date',
    label: 'promotions.pages.gifting.columns.endDate',
    sortable: false,
  },
  {
    type: 'number',
    source: 'priority',
    label: 'promotions.pages.gifting.columns.priority',
    sortable: true,
  },
  {
    type: 'date',
    label: 'promotions.pages.gifting.columns.createdAt',
    source: 'createdAt',
    showDate: true,
    showTime: true,
    sortable: true,
  },
  {
    type: 'date',
    label: 'promotions.pages.gifting.columns.updatedAt',
    source: 'updatedAt',
    showDate: true,
    showTime: true,
    sortable: true,
  },
];

export const GIFT_FILTERS: Filter[] = [
  {
    key: 'id',
    source: 'id',
    type: 'search',
    label: 'promotions.pages.gifting.columns.id',
  },
  {
    key: 'status',
    label: 'promotions.pages.gifting.columns.status',
    source: 'isActive',
    type: 'choose',
    choices: [
      { id: 'true', name: 'Active' },
      { id: 'false', name: 'Inactive' },
    ],
  },
];

export const GIFT_DEFAULT_VALUES = {
  isActive: false,
  name: '',
  dateRange: ['', ''],
  priority: '',
  minimumPurchaseAmount: 0,
  usageLimitCustomer: 1,
  usageLimitAll: 0,
  orderNumberRange: [0, 0],
};
