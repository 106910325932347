import { CSSProperties, useCallback } from 'react';
import {
  RaRecord,
  RecordContextProvider,
  useListContext,
  useTranslate,
} from 'react-admin';
import { useNavigate, useLocation } from 'react-router-dom';
import { FieldTitle } from 'ra-core';
import { DatagridHeaderCellClasses } from 'ra-ui-materialui';
import { TableSortLabel, Tooltip } from '@mui/material';
import clsx from 'clsx';

import { ComponentFactory } from '@Widgets/ResourceList/ComponentFactory/ComponentFactory';
import { ListColumn } from '@Widgets/ResourceList/interface';
import { useIsMobile } from '@ROOT/hooks';
import { MobileLayout } from '@Widgets/ResourceList/ResourceMobileGrid/MobileLayout/MobileLayout';
import { ResourceMobileGrid } from '@Widgets/ResourceList/ResourceMobileGrid/ResourceMobileGrid';
import { DEFAULT_SORTING } from '@Widgets/ResourceList/constants';

import {
  TableWrapper,
  Header,
  Cell,
  Row,
  HeaderClassNames,
  CellClasses,
  TableWrapperClasses,
} from './styles';
import { TableWithFixedColumnsProps } from './interfaces';

const TableWithFixedColumns = <RecordType extends RaRecord = RaRecord>({
  columns,
  stickyColumns = 1,
  rowClick = 'edit',
  showDeleteButton,
  getItemCardCaption,
  defaultSort = DEFAULT_SORTING,
  isInteractive,
  resource,
}: TableWithFixedColumnsProps<RecordType>) => {
  const translate = useTranslate();
  const { data = [], sort, setSort } = useListContext<RecordType>();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const rowTotalWidth = columns.reduce((memo, curr) => {
    const width = convertPixelsToNumber(curr.width) ?? 0;

    return memo + width;
  }, 0);

  const updateSortCallback = useCallback(
    // eslint-disable-next-line
    // @ts-ignore
    (event) => {
      event.stopPropagation();
      const newField = event.currentTarget.dataset.field;
      const newOrder =
        sort.field === newField
          ? sort.order === 'ASC'
            ? 'DESC'
            : 'ASC'
          : event.currentTarget.dataset.order;

      setSort({ field: newField, order: newOrder });
    },
    [sort.field, sort.order, setSort]
  );

  // eslint-disable-next-line
  // @ts-ignore
  const updateSort = setSort ? updateSortCallback : null;

  const handleRowClick = (item: RecordType) => () => {
    if (!rowClick) {
      return;
    }

    if (rowClick === 'edit') {
      navigate(`${pathname}/${item.id}`);

      return;
    }

    if (typeof rowClick === 'function') {
      rowClick(item.id, `${resource}`, item);
    }
  };

  if (isMobile) {
    const getSortingFields = (): SortingField[] => {
      if (!columns.length) return [];

      return columns
        .filter((column) => column.sortable !== false)
        .map((column) => ({
          fieldName: column.source,
          fieldTranslateKey: column.label,
        }));
    };

    return (
      <ResourceMobileGrid
        fields={getSortingFields()}
        showDeleteButton={showDeleteButton}
        getItemCardCaption={getItemCardCaption}
        defaultField={defaultSort.field}
        isInteractive={isInteractive}
        resource={resource}
        rowClick={rowClick}
      >
        {() => <MobileLayout<RecordType> columns={columns} />}
      </ResourceMobileGrid>
    );
  }

  return (
    <TableWrapper className={TableWrapperClasses.root}>
      <Header
        className={HeaderClassNames.root}
        style={{ width: rowTotalWidth }}
      >
        {columns.map((col, index) => {
          const colWidth = convertPixelsToNumber(col.width) ?? 1;
          const sortable = col.sortable ?? false;

          return (
            <Cell
              key={col.source}
              className={HeaderClassNames.headCell}
              isSticky={index < stickyColumns}
              left={getStickyColumnOffset(index, stickyColumns, columns)} // Adjust width dynamically
              style={{ flexBasis: colWidth, flexGrow: 0 }}
            >
              {updateSort && sortable ? (
                <Tooltip
                  title={translate('ra.action.sort')}
                  placement="bottom-start"
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={sort.field === col.source}
                    direction={sort.order === 'ASC' ? 'asc' : 'desc'}
                    data-field={col.source}
                    data-order={sort.field === col.source ? sort.order : 'ASC'}
                    onClick={updateSort}
                    classes={DatagridHeaderCellClasses}
                  >
                    <FieldTitle
                      label={col.labelElement || col.label}
                      source={col.source}
                      resource={resource}
                    />
                  </TableSortLabel>
                </Tooltip>
              ) : (
                <FieldTitle
                  label={col.labelElement || col.label}
                  source={col.source}
                  resource={resource}
                />
              )}
            </Cell>
          );
        })}
      </Header>

      {data.map((row) => (
        <RecordContextProvider value={row} key={row.id}>
          <Row style={{ width: rowTotalWidth }} onClick={handleRowClick(row)}>
            {columns.map((col, colIndex) => {
              const sortable = col.sortable ?? true;
              const colWidth = convertPixelsToNumber(col.width) ?? 1;

              return (
                <Cell
                  key={`${col.source}-${row.id}`}
                  isSticky={colIndex < stickyColumns}
                  left={getStickyColumnOffset(colIndex, stickyColumns, columns)}
                  style={{
                    flexBasis: colWidth,
                    flexGrow: 0,
                  }}
                  className={clsx(CellClasses.root, `column-${col.source}`)}
                >
                  <ComponentFactory<RecordType>
                    sortBy={sortable ? col.source : undefined}
                    key={col.source}
                    column={col}
                    label={col.labelElement || col.label}
                    source={col.source}
                    sortable={col.sortable}
                  />
                </Cell>
              );
            })}
          </Row>
        </RecordContextProvider>
      ))}
    </TableWrapper>
  );
};

const convertPixelsToNumber = (value: CSSProperties['width']) => {
  if (typeof value === 'string') {
    return Number(
      value
        .replace('px', '')
        .replace('pt', '')
        .replace('rem', '')
        .replace('em', '')
    );
  }

  return value;
};

const getStickyColumnOffset = <RecordType extends RaRecord = RaRecord>(
  index: number,
  maxIndex: number,
  columns: ListColumn<RecordType>[]
) => {
  if (index > maxIndex) {
    return undefined;
  }

  const leftItems = columns.slice(0, index);

  return leftItems.reduce((memo, curr) => {
    const width = convertPixelsToNumber(curr.width) ?? 0;

    return memo + width;
  }, 0);
};

export default TableWithFixedColumns;
