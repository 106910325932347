import { styled } from '@mui/material/styles';
import { ListClasses, List } from 'react-admin';

export const StyledResourceList = styled(List)<{ $isMobile: boolean }>`
  .${ListClasses.main} {
    margin-top: 15px;

    .MuiToolbar-root {
      align-items: center;

      .filter-field {
        width: ${({ $isMobile }) => ($isMobile ? '100%' : '215px')};
      }
    }
  }
` as unknown as typeof List;
