import { DateTimeInput, required, useLocaleState } from 'react-admin';
import { FieldValues } from 'react-hook-form';
import isAfter from 'date-fns/isAfter';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import en from 'date-fns/locale/en-US';
import ar from 'date-fns/locale/ar';

import { RowInfoBlock, Flex } from '@UI';
import {
  getPrefixedSource,
  getValueBySource,
} from '@Plugins/Promotions/resources/Promocodes/utils';

import { promocodesTranslationNamespace } from '../../../constants';
import { RESOURCES_SCHEMA } from '../../../schemas/resourcesSchema';

export const validateRangeEnd =
  (sourcePrefix?: string) => (value: string, values: FieldValues) => {
    const [rangeStart] =
      getValueBySource(values, 'dateRange', sourcePrefix) || [];

    if (value && rangeStart && isAfter(new Date(rangeStart), new Date(value))) {
      return promocodesTranslationNamespace.errors.wrongEndDate;
    }

    return undefined;
  };

interface Props {
  sourcePrefix?: string;
}

export const ActiveDates = ({ sourcePrefix }: Props) => {
  const [locale] = useLocaleState();

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={locale === 'ar' ? ar : en}
      locale={locale === 'ar' ? ar : en}
    >
      <RowInfoBlock
        asColumn
        flex={1}
        justifyContent="flex-start"
        titleKey={promocodesTranslationNamespace.labels.activeDates}
        fullWidth
      >
        <Flex asColumn gap={1} fullWidth>
          <DateTimeInput
            label={promocodesTranslationNamespace.labels.startDate}
            name={getPrefixedSource(
              RESOURCES_SCHEMA.dateRange.rangeStart,
              sourcePrefix
            )}
            source={getPrefixedSource(
              RESOURCES_SCHEMA.dateRange.rangeStart,
              sourcePrefix
            )}
            parse={(val) => (val ? new Date(val).toISOString() : '')}
            validate={required()}
            fullWidth
          />

          <DateTimeInput
            label={promocodesTranslationNamespace.labels.endDate}
            name={getPrefixedSource(
              RESOURCES_SCHEMA.dateRange.rangeEnd,
              sourcePrefix
            )}
            source={getPrefixedSource(
              RESOURCES_SCHEMA.dateRange.rangeEnd,
              sourcePrefix
            )}
            parse={(val) => (val ? new Date(val).toISOString() : '')}
            validate={validateRangeEnd(sourcePrefix)}
            fullWidth
          />
        </Flex>
      </RowInfoBlock>
    </LocalizationProvider>
  );
};
