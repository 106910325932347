import { ReactElement, SyntheticEvent } from 'react';
import { useTranslate } from 'react-admin';
import { Button, CircularProgress } from '@mui/material';

import { Flex } from '@UI';
import { DEFAULT_ITEMS_PER_PAGE } from '@MainConstants';
import { ExportXLSXButton } from '@Helpers';
import { ReplenishmentApiResource } from '@Plugins/Replenishment/apiRoutes';
import { FILTERS } from '@Plugins/Replenishment/resources/Calculate/constants';
import { useFilterFactory } from '@Widgets/ResourceList/hooks/useFilterFactory';

import { TableWithFixedColumns } from './components/TableWithFixedColumns';
import { LastSyncDates } from './components/LastSyncDates';
import { LIST_COLUMNS } from './constants';
import { Predict } from './interface';
import { StyledResourceList } from './styled';
import useCalculateJob from './hooks/useCalculateJob';
import { ResourceRoutes } from '@Plugins/resourceRoutes';

export const ListPage = (): ReactElement => {
  const translate = useTranslate();

  const filterComponents = useFilterFactory(FILTERS);

  const { calculate, listData, isCalculating, lastSyncData, isJobProcessing } =
    useCalculateJob();

  const handleCalculate = (e: SyntheticEvent) => {
    e.preventDefault();
    calculate();
  };

  return (
    <>
      <LastSyncDates
        lastSyncData={lastSyncData?.last_synced_entry_date}
        calculationDate={listData[0]?.updated_at}
      />

      <StyledResourceList<Predict>
        empty={false}
        exporter={false}
        filters={filterComponents}
        sort={{ field: 'updated_at', order: 'ASC' }}
        actions={
          <Flex alignItems="center" gap={2}>
            <Button
              variant={isJobProcessing ? 'contained' : undefined}
              onClick={handleCalculate}
              disabled={isCalculating || isJobProcessing}
              endIcon={
                isJobProcessing ? (
                  <CircularProgress size={20} color="secondary" />
                ) : undefined
              }
            >
              {translate(
                isJobProcessing
                  ? 'replenishment.pages.calculate.messages.calculationInProgress'
                  : 'replenishment.pages.calculate.actions.calculate'
              )}
            </Button>
            <ExportXLSXButton
              url={ReplenishmentApiResource.replenishmentExport}
              filename="replenishment"
            />
          </Flex>
        }
        perPage={DEFAULT_ITEMS_PER_PAGE}
      >
        <TableWithFixedColumns
          stickyColumns={3}
          showDeleteButton={false}
          columns={LIST_COLUMNS}
          getItemCardCaption={(record) =>
            translate(
              'replenishment.pages.calculate.labels.mobileCardCaption',
              {
                id: record.id,
              }
            )
          }
          resource={ResourceRoutes.replenishment.calculate.resourcePath}
        />
      </StyledResourceList>
    </>
  );
};
