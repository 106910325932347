import { styled } from '@mui/material/styles';

export const CellClasses = {
  root: 'Cell__root',
};

export const TableWrapperClasses = {
  root: 'TableWrapper__root',
};

export const TableWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  color: #fff;
  overflow-x: auto;
  font-family: Arial, sans-serif;
  font-size: 14px;
  max-width: 100%;
`;

export const Cell = styled('div')<{ isSticky?: boolean; left?: number }>`
  padding: 10px;
  border-bottom: 1px solid rgba(81, 81, 81, 1);
  flex: 1;
  overflow: hidden;
  background-color: #262d38;

  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;

  ${({ isSticky, left }) =>
    isSticky &&
    `
    position: sticky;
    left: ${left}px;
    z-index: 1;
  `}
`;

export const HeaderClassNames = {
  root: 'Header__root',
  headCell: 'Header__headCell',
};

export const Header = styled('div')`
  display: flex;
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 2;

  .${HeaderClassNames.headCell} {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    background-color: ${({ theme }) => theme.palette.background.paper};

    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.5rem;
    letter-spacing: 0.01071em;
  }
`;

export const Row = styled('div')`
  display: flex;
  cursor: pointer;
  &:hover .${CellClasses.root} {
    background-color: #373e47;
  }
`;
