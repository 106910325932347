import { styled } from '@mui/material/styles';

const PREFIX = 'SupplierWeekDayColumn';

export const SupplierWeekDayColumnClasses = {
  value: `${PREFIX}__value`,
  field: `${PREFIX}__field`,
};

export const WEEK_DAY_COLUMN_SIZE = {
  width: 200,
  height: 52,
};

export const Root = styled('span', {
  name: PREFIX,
  overridesResolver: (_, styles) => styles.root,
})`
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
  align-items: center;
  width: ${WEEK_DAY_COLUMN_SIZE.width}px;
  min-height: ${WEEK_DAY_COLUMN_SIZE.height}px;

  .${SupplierWeekDayColumnClasses.value} {
    cursor: pointer;
    padding: 8px 16px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
  }

  .${SupplierWeekDayColumnClasses.field} {
    width: 100%;
    height: 100%;
    margin: 0;

    .MuiInputBase-root {
      min-height: ${WEEK_DAY_COLUMN_SIZE.height - 1}px;

      .MuiInputBase-input {
        padding-inline-start: 16px;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type='number'] {
        -moz-appearance: textfield;
      }
    }
  }
`;
