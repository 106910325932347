import { useContext } from 'react';

import FocusedColumnContext from './context';

export const useFocusedColumnContext = () => {
  const value = useContext(FocusedColumnContext);

  if (!value) {
    throw new Error(
      '`useFocusedColumnContext` can be called only under FocusedColumnContextProvider'
    );
  }

  return value;
};
