import { ResourceInputNumber, Flex, RowInfoBlock } from '@UI';
import { BooleanInput, TextInput } from 'react-admin';

export const PageForm = () => {
  return (
    <>
      <Flex gap={1} fullWidth>
        <RowInfoBlock asColumn flex={1} justifyContent="flex-start">
          <TextInput
            source="store_name"
            label="replenishment.pages.calculate.labels.store"
            disabled
          />

          <TextInput
            source="sku"
            label="replenishment.pages.calculate.labels.sku"
            disabled
          />

          <TextInput
            source="product_name"
            label="replenishment.pages.calculate.labels.productName"
            disabled
          />

          <TextInput
            source="supplier_name"
            label="replenishment.pages.calculate.labels.supplier"
            disabled
          />

          <TextInput
            source="cat1"
            label="replenishment.pages.calculate.labels.category"
            disabled
          />

          <TextInput
            source="company_name"
            label="replenishment.pages.calculate.labels.company"
            disabled
          />

          <ResourceInputNumber
            source="packages"
            label="replenishment.pages.calculate.labels.packageSize"
            required
          />
        </RowInfoBlock>
        <RowInfoBlock asColumn flex={1} justifyContent="flex-start">
          <TextInput
            source="final_cluster"
            label="replenishment.pages.calculate.labels.finalCluster"
            disabled
          />

          <ResourceInputNumber
            source="shelf_life"
            label="replenishment.pages.calculate.labels.shelfLife"
            required
          />

          <BooleanInput
            source="matrix"
            label="replenishment.pages.calculate.labels.productMatrix"
            disabled
          />

          <ResourceInputNumber
            source="sales_llw"
            label="replenishment.pages.calculate.labels.salesLLW"
            disabled
          />

          <ResourceInputNumber
            source="sales_lw"
            label="replenishment.pages.calculate.labels.salesLW"
            disabled
          />

          <ResourceInputNumber
            source="sales_tw"
            label="replenishment.pages.calculate.labels.salesTW"
            disabled
          />

          <ResourceInputNumber
            source="corrected_sales_llw"
            label="replenishment.pages.calculate.labels.correctedSalesLLW"
            disabled
          />
        </RowInfoBlock>
      </Flex>
    </>
  );
};
