import {
  ChoiceFilter,
  ChoiseMultiFilter,
  DateFilter,
  Filter,
  SearchFilter,
  TextFilter,
} from '../interface';
import {
  FilterSearchInput,
  FilterDateInput,
  FilterSelectInput,
  FilterTextInput,
  FilterMultiSelectInput,
} from '../styles';
import { useTranslate } from 'react-admin';
import { useIsMobile } from '@Hooks/useIsMobile';
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export const useFilterFactory = (filters?: Filter[]) => {
  const translate = useTranslate();
  const isMobile = useIsMobile();

  return filters?.map((filter) => {
    const { type, source, label, ...rest } = filter;
    const alwaysOn = rest?.alwaysOn ?? !isMobile;
    const emptyText = (rest as ChoiceFilter).emptyText;

    switch (type) {
      case 'search': {
        return (
          <FilterSearchInput
            key={source}
            {...(rest as SearchFilter)}
            source={source}
            label={label || source || 'search'}
            alwaysOn={alwaysOn}
            resettable
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon color="disabled" />
                </InputAdornment>
              ),
            }}
          />
        );
      }
      case 'date':
        return (
          <FilterDateInput
            {...(rest as DateFilter)}
            label={typeof label === 'string' ? translate(label) : label}
            alwaysOn={alwaysOn}
            source={source}
          />
        );
      case 'text':
        return (
          <FilterTextInput
            key={source}
            {...(rest as TextFilter)}
            label={typeof label === 'string' ? translate(label) : label}
            source={source}
            alwaysOn={alwaysOn}
          />
        );
      case 'choose':
        return (
          <FilterSelectInput
            {...(rest as ChoiceFilter)}
            label={typeof label === 'string' ? translate(label) : label}
            emptyText={emptyText ? translate(emptyText) : ''}
            source={source}
            alwaysOn={alwaysOn}
          />
        );
      case 'choose-multi':
        return (
          <FilterMultiSelectInput
            key={source}
            {...(rest as ChoiseMultiFilter)}
            label={typeof label === 'string' ? translate(label) : label}
            source={source}
            alwaysOn={alwaysOn}
          />
        );
      default:
        return <div />;
    }
  });
};
