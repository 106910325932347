import { ListColumn } from '@Widgets/ResourceList/interface';

import { WEEK_DAY_COLUMN_SIZE } from './components/SupplierWeekDayColumn/styles';
import { SupplierWeekDayColumn } from './components/SupplierWeekDayColumn';
import { Supplier, SupplierRow } from './interface';

export const LIST_COLUMNS: ListColumn<Supplier>[] = [
  {
    source: 'name',
    label: 'replenishment.pages.suppliers.labels.name',
    type: 'text',
  },
];

export const WEEK_DAYS = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export const SUPPLIER_DETAIL_COLUMNS: ListColumn<SupplierRow>[] = [
  {
    source: 'store',
    label: 'replenishment.pages.suppliers.labels.store',
    type: 'text',
    sortable: false,
    width: 150,
  },
  ...WEEK_DAYS.map(
    (day, index) =>
      ({
        source: day,
        label: `replenishment.pages.suppliers.labels.weekDays.${day}`,
        sortable: false,
        type: 'custom',
        customComponent: <SupplierWeekDayColumn weekDay={index} />,
        width: WEEK_DAY_COLUMN_SIZE.width,
      } as unknown as ListColumn<SupplierRow>)
  ),
];
