import styled from 'styled-components';
import { styled as muiStyled } from '@mui/material/styles';

import { Flex } from '@UI';
import {
  TableWrapperClasses,
  CellClasses,
  HeaderClassNames,
} from '@Plugins/Replenishment/resources/Calculate/components/TableWithFixedColumns/styles';

import { WEEK_DAY_COLUMN_SIZE } from './components/SupplierWeekDayColumn/styles';

const SUPPLIER_NAME_PREFIX = 'SupplierName';

export const SupplierNameClasses = {
  title: `${SUPPLIER_NAME_PREFIX}__title`,
  caption: `${SUPPLIER_NAME_PREFIX}__caption`,
};

export const SupplierName = muiStyled('div', {
  name: SUPPLIER_NAME_PREFIX,
  overridesResolver: (_, styles) => styles.root,
})`
  padding-inline: 16px;
  padding-bottom: 36px;
  background: ${({ theme }) => theme.palette.background.paper};
  
  .${SupplierNameClasses.title} {
    margin-bottom: 0;
  }
  
  .${SupplierNameClasses.caption} {
    color: ${({ theme }) => theme.palette.text.disabled};
  }
`;

export const Root = styled(Flex)`
  width: 100%;

  .${TableWrapperClasses.root} {
    padding-bottom: 16px;
  }

  .${HeaderClassNames.headCell} {
    padding: 10px 16px;
  }

  .${CellClasses.root} {
    padding: 10px 16px;

    &.column-monday,
    &.column-tuesday,
    &.column-wednesday,
    &.column-thursday,
    &.column-friday,
    &.column-saturday,
    &.column-sunday {
      padding: 0;
      width: ${WEEK_DAY_COLUMN_SIZE.width}px;
      min-height: ${WEEK_DAY_COLUMN_SIZE.height}px;
    }
  }
`;
