import { RequestAPI } from '@RestApi';
import { ReplenishmentApiResource } from '@Plugins/Replenishment/apiRoutes';

import { UpdateSupplyGapRequest, UpdateSupplyResponse } from './interface';

export const requestSupplyGapUpdate = async (
  data: UpdateSupplyGapRequest
): Promise<UpdateSupplyResponse> => {
  const { id, ...restData } = data;

  if (id) {
    return RequestAPI.put(
      `${ReplenishmentApiResource.schedules}/${id}`,
      restData
    );
  }

  return RequestAPI.post(ReplenishmentApiResource.schedules, restData);
};
