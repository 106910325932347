export const TRANSLATION_SCHEMA = {
  labels: {
    code: 'code',
    applyToDiscountedItems: 'applyToDiscountedItems',
    startDate: 'startDate',
    endDate: 'endDate',
    orderRange: 'orderRange',
    promoId: 'promoId',
    type: 'type',
    value: 'value',
    discountValue: 'discountValue',
    applyTo: 'applyTo',
    anyItemsFrom: 'anyItemsFrom',
    atDiscountedValue: 'atDiscountedValue',
    customerGets: 'customerGets',
    customerBuys: 'customerBuys',
    minRequirements: 'minRequirements',
    customerEligibility: 'customerEligibility',
    usageLimits: 'usageLimits',
    defaultIs1IfNoSelected: 'defaultIs1IfNoSelected',
    activeDates: 'activeDates',
    name: 'name',
    nameActual: 'nameActual',
    email: 'email',
    status: 'status',
    maxUsed: 'maxUsed',
    used: 'used',
    quantity: 'quantity',
    activityRange: 'activityRange',
    ownersAndSellers: 'ownersAndSellers',
    maximumUsageNumberPerOrder: 'maximumUsageNumberPerOrder',
    isActive: 'isActive',
    active: 'active',
    id: 'id',
    createdAt: 'createdAt',
    updatedAt: 'updatedAt',
    default: 'default',
  },
  errors: {
    wrongEndDate: 'wrongEndDate',
    pleaseProvideMinRequirements: 'pleaseProvideMinRequirements',
  },
  actions: {
    generateCode: 'generateCode',
    activateEndDate: 'activateEndDate',
  },
  captions: {
    desktop: 'desktop',
    mobile: 'mobileCaption',
  },
  lists: {
    discountCode: {
      caption: 'caption',
      placeholder: 'placeholder',
      description: 'description',
    },
    orderNumberRange: {
      startOrderNumber: 'startOrderNumber',
      endOrderNumber: 'endOrderNumber',
    },
    minRequirements: {
      caption: 'caption',
      none: 'none',
      minimumPurchaseAmount: 'minimumPurchaseAmount',
      minimumQuantityItems: 'minimumQuantityItems',
    },
    customerEligibility: {
      everyone: 'everyone',
      specificCustomerSegment: 'specificCustomerSegment',
      specificCustomerSegmentPlaceholder: 'specificCustomerSegmentPlaceholder',
      specificCustomers: 'specificCustomers',
      specificCustomersPlaceholder: 'specificCustomersPlaceholder',
    },
    usageLimit: {
      forAll: 'forAll',
      forAllCount: 'forAllCount',
      perUser: 'perUser',
      perUserCount: 'perUserCount',
    },
    type: {
      all: 'all',
      percent: 'percent',
      fixed: 'fixed',
      freeShipping: 'freeShipping',
      buyXGetY: 'buyXGetY',
    },
    applyTo: {
      all: 'all',
      specificCollection: 'specificCollection',
      specificCollectionPlaceholder: 'specificCollectionPlaceholder',
      specificProducts: 'specificProducts',
      specificProductsPlaceholder: 'specificProductsPlaceholder',
    },
    status: {
      all: 'all',
      active: 'active',
      inactive: 'inactive',
    },
  },
};
