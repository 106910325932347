import { useRecordContext, ArrayField } from 'react-admin';
import { Typography } from '@mui/material';

import { Flex } from '@UI';
import { TableWithFixedColumns } from '@Plugins/Replenishment/resources/Calculate/components/TableWithFixedColumns';

import { FocusedColumnContextProvider } from './contexts/FocusedColumnContext';
import { Supplier } from './interface';
import { SUPPLIER_DETAIL_COLUMNS } from './constants';
import { Root, SupplierName, SupplierNameClasses } from './styles';

export const PageForm = () => {
  const supplier = useRecordContext<Supplier>();

  return (
    <Flex fullWidth asColumn>
      <SupplierName>
        <h1 className={SupplierNameClasses.title}>{supplier.name}</h1>
        <Typography variant="caption" className={SupplierNameClasses.caption}>
          ID: {supplier.id}
        </Typography>
      </SupplierName>

      <ArrayField source="rows">
        <Root fullWidth asColumn justifyContent="stretch">
          <FocusedColumnContextProvider>
            <TableWithFixedColumns
              columns={SUPPLIER_DETAIL_COLUMNS}
              stickyColumns={1}
              showDeleteButton={false}
              getItemCardCaption={(item) => item.store}
              rowClick={false}
            />
          </FocusedColumnContextProvider>
        </Root>
      </ArrayField>
    </Flex>
  );
};
