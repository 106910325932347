import { PluginResourceController } from '@PluginBase';
import {
  GetListResult,
  DeleteResult,
  GetListParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from 'react-admin';
import { ResourceRoutes } from '../../../resourceRoutes';
import { ListPage } from './ListPage';
import { ReplenishmentApiResource } from '../../apiRoutes';
import { ResourceItemPage } from '@Widgets/ResourceItemPage/ResourceItemPage';
import { PageForm } from './PageForm';
import {
  Supplier,
  ResponseData,
  SchedulersByStores,
  SupplierRow,
} from './interface';
import { ReplenishmentCrud } from '../../crud';
import { RequestAPI } from '@RestApi';

const suppliersCrud = new ReplenishmentCrud(ReplenishmentApiResource.suppliers);

const SuppliersController = new PluginResourceController({
  resourceRoute: {
    name: ResourceRoutes.replenishment.suppliers.resourcePath,
    list: ListPage,
    edit: (
      <ResourceItemPage type="edit" includeForm={false}>
        <PageForm />
      </ResourceItemPage>
    ),
  },
  menuItem: {
    route: ResourceRoutes.replenishment.suppliers.routePath,
    caption: {
      translationKey: 'replenishment.pages.suppliers.caption',
    },
  },
  dataProvider: {
    getList: (
      resource: string,
      params: GetListParams
    ): Promise<GetListResult> => {
      return suppliersCrud.list(params);
    },
    getOne: async (resource, params): Promise<GetOneResult> => {
      const result = await suppliersCrud.getOne<{ data: Supplier }>(params);

      if (!result?.data) {
        return { data: undefined };
      }

      const { data }: ResponseData = await RequestAPI.get(
        `${ReplenishmentApiResource.schedules}/${result.data.id}`
      );

      const schedulersByStores = data.schedules.reduce<SchedulersByStores>(
        (acc, schedule) => {
          const { store_id, order_day } = schedule;

          if (!(store_id in acc)) acc[store_id] = new Array(7).fill(null);
          acc[store_id][order_day - 1] = schedule;

          return acc;
        },
        {}
      );

      result.data.rows = data.stores.map((store) => {
        const schedules =
          schedulersByStores[store.id] || new Array(7).fill(null);

        return {
          id: store.id,
          store: store.name,
          ...schedules,
        } as SupplierRow;
      });

      return {
        data: result?.data,
      };
    },
    delete: async (resource, params): Promise<DeleteResult> => {
      await suppliersCrud.delete(params);

      return { data: params.previousData };
    },
    update: async (
      resource: string,
      params: UpdateParams
    ): Promise<UpdateResult> => {
      if (params.meta === 'load') {
        await suppliersCrud.uploadFile_(
          ReplenishmentApiResource.supplierScheduleLoad,
          params.data.file
        );

        return { data: { id: 1 } };
      } else {
        return {
          data: params.previousData,
        };
      }
    },
  },
});

export default SuppliersController;
