import { useState, useCallback } from 'react';
import {
  useTranslate,
  useNotify,
  useRefresh,
  useRecordContext,
} from 'react-admin';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';

import { requestSupplyGapUpdate } from '../../utils';
import { Supplier, UpdateSupplyGapRequest } from '../../interface';
import FocusedColumnContext from './context';
import {
  FocusedColumnContextProviderProps,
  DirtyFieldValues,
} from './interfaces';

const FocusedColumnContextProvider = ({
  children,
}: FocusedColumnContextProviderProps) => {
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const supplier = useRecordContext<Supplier>();

  const [focusedId, setFocusedId] = useState<Nullable<string>>(null);
  const [dirtyColumns, setDirtyColumns] = useState<DirtyFieldValues>({});

  const [nominatedToConfirmId, setNominatedToConfirmId] =
    useState<Nullable<string>>(null);

  const handleSetFocusedId = useCallback(
    (id: Nullable<string>) => {
      if (!Object.keys(dirtyColumns).length) {
        setFocusedId(id);

        return;
      }

      setNominatedToConfirmId(id);
    },
    [focusedId, dirtyColumns]
  );

  const handleCloseConfirmModal = () => {
    if (!nominatedToConfirmId || !focusedId) {
      return;
    }

    setDirtyColumns({});
    setFocusedId(nominatedToConfirmId);
    setNominatedToConfirmId(null);
  };

  const confirmFieldFocusChange = async () => {
    if (!focusedId) {
      return;
    }

    const value = dirtyColumns[focusedId];

    if (!value) {
      return;
    }

    const [rowId, weekDay] = focusedId.split('.');
    const targetRow = supplier.rows?.find((item) => item.id === rowId);

    if (!targetRow) {
      return;
    }

    const existingSchedule = targetRow[Number(weekDay)];

    updateCellValue({
      openNominated: true,
      id: existingSchedule?.id,
      store_id: targetRow.id,
      supplier_id: supplier.id,
      order_day: Number(weekDay) + 1,
      supply_gap: Number(value),
    });
  };

  const clearFocusedId = () => {
    setFocusedId(null);
    setDirtyColumns({});
    setNominatedToConfirmId(null);
  };

  const { mutate: updateCellValue, isLoading } = useMutation({
    mutationKey: 'updateCellValue',
    mutationFn: async (
      data: UpdateSupplyGapRequest & { openNominated?: boolean }
    ) => {
      const { openNominated, ...rest } = data;

      const response = await requestSupplyGapUpdate(rest);

      return {
        response,
        openNominated,
      };
    },
    onSuccess: ({ openNominated }) => {
      notify('replenishment.pages.suppliers.messages.updateSuccess', {
        type: 'success',
        autoHideDuration: 10000,
      });
      refresh();
      clearFocusedId();

      if (openNominated) {
        setFocusedId(nominatedToConfirmId);
        setNominatedToConfirmId(null);
      }
    },
    onError: (error) => {
      if (error instanceof AxiosError) {
        const status = error.response?.status;

        notify('replenishment.pages.suppliers.errors.failed', {
          type: 'error',
          autoHideDuration: 10000,
          messageArgs: { status },
        });
      }
    },
  });

  return (
    <FocusedColumnContext.Provider
      value={{
        focusedId,
        setFocusedId: handleSetFocusedId,
        clearFocusedId,
        dirtyColumns,
        setDirtyColumns,
        updateCellValue,
        isUpdating: isLoading,
      }}
    >
      {children}

      <Dialog
        open={Boolean(nominatedToConfirmId)}
        onClose={handleCloseConfirmModal}
      >
        <DialogTitle>
          {translate('replenishment.pages.suppliers.labels.confirmAction')}
        </DialogTitle>
        <DialogContent>
          {translate(
            'replenishment.pages.suppliers.messages.confirmFieldClose'
          )}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isLoading}
            color="error"
            onClick={handleCloseConfirmModal}
          >
            {translate('replenishment.pages.suppliers.actions.discard')}
          </Button>
          <Button
            disabled={isLoading}
            variant="contained"
            onClick={confirmFieldFocusChange}
          >
            {translate('ra.action.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </FocusedColumnContext.Provider>
  );
};

export default FocusedColumnContextProvider;
